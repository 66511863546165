import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useAPI } from '../../hooks';

import PayPalConnect from './PayPalConnect';
import styles from './Settings.module.scss';

import { UPDATE_TEACHER, getTeacher } from 'actions/UserActions';
import { timezoneApi } from 'common/api/timezone-api';
import loadGMaps from 'common/scripts/loadGMaps';
import AvatarField from 'components/AvatarField';
import Btn from 'components/Btn';
import DropdownSelect from 'components/DropdownSelect';
import InputField from 'components/InputField';
import MultiSelect from 'components/MultiSelect';
import NOTIFICATIONS from 'data/notifications/settings';
import { InstrumentsType, OptionsType, User } from 'types';
import { config } from 'utils/config';

type SettingsProps = {
  user: User;
  allApis: any;
  dispatch: any;
};

type TUploadAvatarException = {
  picture: string[];
};

const useInstruments = () => {
  const options = {
    select: ({ results }: { results: InstrumentsType[] }) =>
      results.map(i => ({ id: i.id, value: i.id, label: i.name })),
  };
  const { data } = useAPI('/instruments/', { limit: 1000 }, options);
  return data;
};

const Settings = (props: SettingsProps) => {
  const {
    user,
    allApis: { getJson, patchJson, patchMultipartPayloadJson },
  } = props;
  const dispatch = useDispatch();
  const instruments = useInstruments();
  const [timezones, setTimezones] = useState<any[]>([]);
  const [avatarError, setAvatarError] = useState<string>();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const instrumentsSchema = yup.object<InstrumentsType>().shape({
    id: yup.number().required(),
    value: yup.number().required(),
    label: yup.string().required(),
  });

  const schema = yup.object().shape({
    first_name: yup.string().required('Fisrt name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Email is invalid').required('Email address is required'),
    picture: yup.string().required(),
    nickname: yup.string().nullable(),
    instruments: yup.array().of(instrumentsSchema).required(),
    timezone: yup.string().required(),
    about_me: yup.string().nullable(),
    formatted_address: yup.string().nullable(),
    is_searchable: yup.boolean().nullable(),
    school_name: yup.string().nullable(),
    school_location: yup.string().nullable(),
    location: yup.string().nullable(),
    locality: yup.string().nullable(),
    administrative_area_level_1: yup.string().nullable(),
    country: yup.string().nullable(),
    website_enabled: yup.boolean(),
    website_template: yup.string(),
  });

  const formMethods = useForm({
    defaultValues: schema.cast({
      ...user,
      instruments: user.instruments.map(i => ({ id: i.id, value: i.id, label: i.name })),
    }),
    resolver: yupResolver(schema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const getTimezonesData = useCallback(async () => {
    const resp = await timezoneApi.getTimezones(getJson);
    setTimezones(resp.map((r: any) => ({ value: r, label: r })));
  }, [getJson]);

  const initGMaps = useCallback(() => {
    const schoolAutocomplete = new (window as any).google.maps.places.Autocomplete(
      document.getElementById('school_name') as HTMLInputElement,
      {
        types: ['establishment'],
      },
    );

    const addressAutocomplete = new (window as any).google.maps.places.Autocomplete(
      document.getElementById('formatted_address') as HTMLInputElement,
      {
        types: ['(cities)'],
      },
    );

    schoolAutocomplete.addListener('place_changed', () => {
      const place = schoolAutocomplete.getPlace();
      setValue('school_name', place.name);
      setValue('school_location', place.formatted_address || '');
    });

    addressAutocomplete.addListener('place_changed', () => {
      const place = addressAutocomplete.getPlace();
      const location = Object.values(place?.geometry?.location?.toJSON() || {}).join(' ');
      setValue('formatted_address', place.formatted_address || '');
      setValue('location', `SRID=4326;POINT (${location})`);
      setValue('locality', place.name);

      ['country', 'administrative_area_level_1'].forEach(t => {
        place?.address_components?.forEach((c: any) => {
          if (c.types.indexOf(t) > -1) {
            if (t === 'country') setValue('country', c.long_name);
            else setValue('administrative_area_level_1', c.long_name);
          }
        });
      });
    });
  }, [setValue]);

  useEffect(() => {
    loadGMaps(initGMaps);
    getTimezonesData();
  }, [getTimezonesData, initGMaps]);

  const updateProfilePic = (picture: any) => {
    patchMultipartPayloadJson('/study/teacher/?embed=instruments', { picture })
      .then((data: User) => {
        dispatch({
          type: UPDATE_TEACHER + '_SUCCESS',
          payload: { data },
        });
        setAvatarError(undefined);
      })
      .catch(async (errorPromise: Promise<TUploadAvatarException>) => {
        const exception = await errorPromise;
        setAvatarError(exception.picture[0]);
      });
  };

  const onUpdateNewImageFile = (file: any) => {
    setValue('picture', file);
    updateProfilePic(file);
  };

  const onSelectInstrucment = (values: MultiValue<OptionsType>) => {
    setValue('instruments', values as TSettingValues['instruments']);
  };

  type TSettingValues = yup.InferType<typeof schema>;

  const onSubmit = async ({ website_enabled, website_template, ...values }: TSettingValues) => {
    try {
      setIsLoadingSubmit(true);

      const payload = {
        ..._.omit(values, ['picture']),
        ...(typeof values.picture === 'object' && { picture: values.picture }),
        instruments: values.instruments.map(v => v.id),
        website: { enabled: website_enabled, template: website_template },
      };

      patchJson('/study/teacher/', payload)
        .then(() => {
          toast.success(NOTIFICATIONS.update.success);
          dispatch(getTeacher());
        })
        .catch(() => toast.error(NOTIFICATIONS.update.error))
        .finally(() => setIsLoadingSubmit(false));
    } catch (e) {
      setIsLoadingSubmit(false);
    }
  };

  const FormField = ({ label, className, children }: any) => (
    <div className={styles['setting-item']}>
      <label className={styles['setting-item__label']}>{label}</label>
      <div className={`${styles['setting-item__value']} ${className}`}>{children}</div>
    </div>
  );

  return (
    <div className={styles['settings-wrapper']}>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField label="Profile Photo">
            <AvatarField
              user={user}
              value={user.picture}
              onUpdateNewImageFile={onUpdateNewImageFile}
              error={avatarError}
            />
          </FormField>
          <FormField label="First name">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              size="small"
              inputProps={{ placeholder: 'John', ...register('first_name') }}
              error={errors.first_name?.message}
            />
          </FormField>
          <FormField label="Last name">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              size="small"
              inputProps={{ placeholder: 'Smith', ...register('last_name') }}
              error={errors.last_name?.message}
            />
          </FormField>
          <FormField label="Email Address">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              size="small"
              inputProps={{
                placeholder: 'john.smith@theinternet.com',
                ...register('email'),
              }}
              error={errors.email?.message}
            />
          </FormField>
          <FormField label="Custom profile URL">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              addOn={`${window.location.host}${config.public_url}/teachers/`}
              size="small"
              inputProps={{
                placeholder: '95212',
                ...register('nickname'),
              }}
            />
            <p className={styles['desc-input']}>
              Up to 30 characters. Letters, digits, and -/_ only. <br />
              Must start with a letter
            </p>
          </FormField>
          <FormField label="Enable public personal website">
            <Form.Check type="switch" {...register('website_enabled')} />
          </FormField>
          {watch('website_enabled') && (
            <FormField label="Website template">
              <Form.Select {...register('website_template')}>
                <option value="">Default</option>
                <option value="green">Green</option>
                <option value="orange">Orange</option>
              </Form.Select>
            </FormField>
          )}
          <FormField label="Instrument(s) Taught">
            <MultiSelect options={instruments} onChange={onSelectInstrucment} value={watch('instruments')} />
          </FormField>
          <FormField label="Timezone">
            <DropdownSelect
              label={timezones.find(option => option.value === watch('timezone'))?.label}
              value={timezones.find(option => option.value === watch('timezone'))?.label}
              options={timezones}
              classNameToggle={styles['group-dropdown']}
              onChange={(value: string) => setValue('timezone', value)}
              isClearable
              onClear={() => setValue('timezone', '')}
            />
          </FormField>
          <FormField label="About">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              size="small"
              inputProps={{
                rows: 4,
                as: 'textarea',
                placeholder: `Hi there! I’m John Smith, and I love teaching students to how to play Piano and Guitar. I have over 8 years experience in the music field, playing and teaching in the San Francisco, California area.`,
                ...register('about_me'),
              }}
            />
          </FormField>
          <FormField label="City, state">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              size="small"
              inputProps={{
                placeholder: 'Los Angeles, CA, USA',
                id: 'formatted_address',
                ...register('formatted_address'),
              }}
            />
          </FormField>
          <FormField label="Allow students to search for my class in the Practice app">
            <Form.Check type="switch" {...register('is_searchable')} />
          </FormField>
          <div className={styles['title-wrapper']}>School</div>
          <FormField label="School Name">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              size="small"
              inputProps={{
                placeholder: 'Silvelake Conservatory of Music',
                id: 'school_name',
                ...register('school_name'),
              }}
            />
          </FormField>
          <FormField label="Address">
            <InputField
              className={styles['input-field-wrapper']}
              inputClassName={styles['input-field']}
              size="small"
              inputProps={{
                placeholder: '1453 Carson Lane, Melrose, Los Angeles, California',
                id: 'school_location',
                ...register('school_location'),
              }}
            />
          </FormField>
          <div className={styles['title-wrapper']}>Billing</div>
          <FormField label="PayPal">
            <PayPalConnect />
          </FormField>
          <div className={styles['footer-wrapper']}>
            <Btn variant="primary" className={styles['btn-submit']} type="submit" isLoading={isLoadingSubmit}>
              Update Settings
            </Btn>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user as User,
  allApis: state.Api.allApis,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch: (action: any) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
